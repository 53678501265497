import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import AuthService from './AuthService.jsx'
import { withRouter } from 'react-router-dom'
import { ReactComponent as Fax } from './logo.svg'

const Auth = AuthService.getInstance()
export default withRouter(props => {
  const [isnavvisible, setisnavvisible] = useState(false)
  const logOut = () => {
    props.history.replace('/signin')
    Auth.logOut()
  }
  return (
    <nav className={`navbar fixed-top navbar-dark bg-dark navbar-expand-md `}>
      <span className="navbar-brand">
        <Fax width="70" className={`text-white`} />
        FaxBau 2.0
      </span>
      <button
        className="navbar-toggler"
        type="button"
        onClick={() => {
          setisnavvisible(!isnavvisible)
        }}
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>
      <div
        className={`collapse justify-content-end navbar-collapse  ${isnavvisible ? `show` : ``}`}
        id="navbarNav"
      >
        <ul className={`navbar-nav`}>
          {Auth.loggedIn() && (
            <React.Fragment>
              {/*<li className="nav-item">
                <NavLink
                  to="/setting"
                  className="nav-link"
                  activeClassName="active"
                >
                  Settings
                </NavLink>
                  </li>*/}
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/"
                  exact
                  className="nav-link"
                  activeClassName="active">
                  Faxen
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/hartzbreaker"
                  className="nav-link"
                  activeClassName="active"
                >
                  Hartzbreaker
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/verlosung/teilnehmer"
                  className="nav-link"
                  activeClassName="active"
                >
                  Teilnehmer
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/verlosung/duplikate"
                  className="nav-link"
                  activeClassName="active"
                >
                  Duplikate
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/verlosung/duplikate-untersuchen"
                  className="nav-link"
                  activeClassName="active"
                >
                  Duplikate untersuchen
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/verlosung/gewinner"
                  className="nav-link"
                  activeClassName="active"
                >
                  Gewinner
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/verlosung/geblockt"
                  className="nav-link"
                  activeClassName="active"
                >
                  Geblockt
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/articles"
                  className="nav-link"
                  activeClassName="active"
                >
                  Articles
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  onClick={() => {
                    setisnavvisible(false)
                  }}
                  to="/bankdonations"
                  className="nav-link"
                  activeClassName="active"
                >
                  BankDonations
                </NavLink>
              </li>
            </React.Fragment>
          )}
          {Auth.loggedIn() && (
            <React.Fragment>
              <li className="nav-item">
                <button
                  className="ml-md-3 btn btn-outline-danger nav-item"
                  href="#"
                  onClick={logOut}
                >
                  Logout
                </button>
              </li>
            </React.Fragment>
          )}
        </ul>
      </div>
    </nav>
  )
})
