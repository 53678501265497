import React, { useEffect, useState, useContext } from 'react'
import './App.css'
import withAuth from './withAuth.js'
import { AppContext } from './AppProvider.jsx'
import AuthService from './AuthService.jsx'
import WithLoading from './WithLoading.jsx'
import Pagination from './Pagination.jsx'
import ActionButton from './components/ActionButton.jsx'
import DuplicateFinder from './DuplicateFinder.jsx'


const Auth = AuthService.getInstance()
export default withAuth(function RaffleDuplicates(props) {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(false)
  const [statusMessage, setStatusMessage] = useState()
  const dateOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }
  useEffect(() => {
    if (update) {
      setLoading(true)
      try {
        Auth.fetch(`/verlosung/duplikate-untersuchen`)
          .then(data => {
            dispatch({
              type: 'FETCH_RAFFLEDUPLICATES', data: data.rows
                .sort((a, b) => new Date(b.value.birthdate) - new Date(a.value.birthdate))
            })
            setLoading(false)
            setUpdate(false)
          })
          .catch(error => console.error(error))
      } catch (error) {
        console.error(error)
        setLoading(false)
        setUpdate(false)
      }
      return () => {
        setLoading(false);
        setUpdate(false)
      };
    }
  }, [update, dispatch])

  useEffect(() => {
    setUpdate(true)
  }, [])

  const removeMark = async (e, id) => {
    e.preventDefault()
    if (e.target.dataset.confirm && !window.confirm(e.target.dataset.confirm)) {
      return;
    }
    await Auth.fetch(`/verlosung/remove-marked/${id}`, { method: 'POST' })
    setStatusMessage(`Markierung entfernt.`)
    return setUpdate(true)
  }

  const fetchDocs = async (userData) => {
    let arr = []
    await userData.map(user => {
      const id = user.id.replace(/'/g, '"')
      arr.push(id)
    })
    return Auth.fetch(`/verlosung/fetch-docs`, {
      method: 'POST',
      body: JSON.stringify(arr)
    })
      .then(res => { return res })
  }

  const blockDuplicates = async (e) => {
    e.preventDefault()
    setLoading(true)
    fetchDocs(state.duplicates).then((data) => {
      Auth.fetch(`/verlosung/bulk-block`, {
        method: 'POST',
        body: JSON.stringify(data)
      })
      setLoading(false)
      setStatusMessage(`Fertig! ${state.duplicates.length} Duplikate wurden geblockt.`)
      return setUpdate(true)
    })
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3 order-md-2">
          <div className="row justify-content-center fixed-height-100">
            {statusMessage &&
              <div className="alert alert-info text-center" role="alert">
                {statusMessage}
              </div>
            }
          </div>
          <div className="row justify-content-center fixed-height-100">
            {state.duplicates && state.duplicates.length > 0 &&
              <div className="col">
                <div className="alert alert-danger text-center" role="alert">
                  <p>{state.duplicates.length} Verdächtige{state.duplicates.length === 1 ? "r" : ""} Teilnehmer {state.duplicates.length === 1 ? "kann" : "können"} blockiert werden.</p>
                  <button onClick={blockDuplicates} type="button" className="btn btn-danger">Teilnehmer blockieren</button>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="col-md-9 order-md-1">
          <WithLoading loading={loading} waitfor={state.duplicates}>

            <table className="table table-sm raffle table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>E-Mail</th>
                  <th>Angemeldet seit</th>
                  <th>Status</th>
                  <th>Geburtsdatum</th>
                </tr>
              </thead>
              <tbody id="participant-table">
                {state.duplicates && state.duplicates.map(participant => (
                  <React.Fragment key={participant.id}>
                    <tr className={participant && participant.value.status === "winner" ? "bg-info winner-all" :
                      participant.value.status === "blocked" ? "bg-warning blocked" : "confirmed"}>
                      <td className='action'>
                        <ActionButton
                          type={`${participant.value.status === "winner" ? "remove" :
                            participant.value.status === "blocked" ? "restore" :
                              "block"}`}
                          onClick={(e) =>
                            participant.value.duplicate === true ? removeMark(e, participant.id) : ""}
                          title={`${participant.value.status === "winner" ? "Gewinner entfernen" :
                            participant.value.status === "blocked" ? "Blockierung aufheben." :
                              "Teilnehmer blockieren"}`} />
                      </td>
                      <td className='name'>{participant &&
                        <a href={`/verlosung/teilnehmer/${participant.id}`}>
                          {participant.value.firstname} {participant.value.lastname}
                        </a>}
                      </td>
                      <td className='email'>
                        {participant && <a href={`mailto:${participant.value.email}`} >{participant.value.email}</a>}
                      </td>
                      <td className='date'>{participant && new Intl.DateTimeFormat("de-DE", dateOptions).format(Date.parse(participant.value.createdAt))}</td>
                      <td className='status'>{participant && participant.value.status}</td>
                      <td className='birthdate'>{participant && participant.value.birthdate}</td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </WithLoading>
        </div>
      </div>
    </div>
  )
})
